import { Message } from "element-ui";
import request from "./Request";
import wx from "weixin-js-sdk";
const getShareInfo = function (shareData) {
  //获取url链接（如果有#需要这么获取）
  var url = window.location.href.split("#")[0];
  //向后端发送请求拿取签名
  request.post('getWechatSignature',{url}).then((res) => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: res.data.data.appid, // 必填，公众号的唯一标识
      timestamp: parseInt(res.data.data.timestamp), // 必填，生成签名的时间戳
      nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
      signature: res.data.data.signature, // 必填，签名
      jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的 JS 接口列表
    });
    wx.ready(() => {
      //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      wx.updateAppMessageShareData(shareData);
      //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
      wx.updateTimelineShareData(shareData);
    });
    //错误了会走 这里
    wx.error(function (res) {
      return Message({
        message: "微信分享错误信息",
        type: "error",
      });
    });
  });
};

export default getShareInfo;
